
import Vue from 'vue'

export default Vue.extend({
  name: 'CVing',
  data() {
    return {
      isMobile: true,
      resizeTimeout: null
    }
  },
  head () {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
  watch: {
    $route() {
      this.$nuxt.$loading?.start()
      if (this.$auth.loggedIn) {
        this.$axios.get('/proxyapi/auth/get-csrf-token')
        this.$axios.get('/proxystorage/auth/get-csrf-token')
      }

      // Aspetta 250ms prima di terminare il caricamento
      setTimeout(() => {
        this.$nuxt.$loading?.finish()
      }, 250)
    }

  },
  mounted () {
    if (this.$auth.loggedIn) {
      this.$axios.get('/proxyapi/auth/get-csrf-token')
      this.$axios.get('/proxystorage/auth/get-csrf-token')
    }
    window.addEventListener('resize', this.checkIfMobile)
    this.checkIfMobile()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile)
  },
  methods: {
    checkIfMobile() {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        if (typeof window !== 'undefined') {
          this.isMobile = window.innerWidth < 768
        }
      }, 500)
    }
  }
})
